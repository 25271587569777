import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Alert, Card, Image, InputGroup } from 'react-bootstrap';
import useAuth from '../hooks/useAuth';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Ojos de react-icons
import logo from '../assets/recargacreditos-02.svg';
import backgroundImage from '../assets/fondo.webp';

const Login = () => {
  const [identificador, setIdentificador] = useState('');
  const [contrasenia, setContrasenia] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Estado para la visibilidad de la contraseña
  const [error, setError] = useState('');
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login({ identificador, contrasenia });
      navigate('/');
    } catch (err) {
      setError('Error en el inicio de sesión');
    }
  };

  if (isAuthenticated) {
    navigate('/');
  }

  const containerStyle = {
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={containerStyle}>
      <Card style={{ width: '100%', maxWidth: '400px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
        <Card.Header className="text-center" style={{ backgroundColor: '#009bdc', borderRadius: '10px 10px 0 0', padding: '15px 0' }}>
          <Image src={logo} style={{ width: '180px' }} alt="Logotipo" />
        </Card.Header>
        <Card.Body style={{ padding: '20px 30px' }}>
          <h2 className="text-center mb-4" style={{ color: '#0A74DA' }}>Iniciar Sesión</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicIdentificador">
              <Form.Label>Correo Electrónico o Nombre de Tienda</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa tu correo o nombre de tienda"
                value={identificador}
                onChange={(e) => setIdentificador(e.target.value)}
                required
                style={{ borderColor: '#ddd', borderRadius: '5px' }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label>Contraseña</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"} // Cambia el tipo según el estado
                  placeholder="Contraseña"
                  value={contrasenia}
                  onChange={(e) => setContrasenia(e.target.value)}
                  required
                  style={{ borderColor: '#ddd', borderRadius: '5px' }}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowPassword(!showPassword)} // Alterna la visibilidad de la contraseña
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Ícono según el estado */}
                </Button>
              </InputGroup>
            </Form.Group>
            <Button variant="primary" type="submit" style={{ width: '100%', marginTop: '20px', backgroundColor: '#0A74DA', borderColor: '#0A74DA' }}>
              Iniciar Sesión
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
