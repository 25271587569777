import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Button, OverlayTrigger,Modal, Form, Row, Col, Alert, InputGroup, FormControl, Pagination, ButtonGroup, Dropdown, DropdownButton, Tooltip} from 'react-bootstrap';
import { FaPlus, FaAddressBook } from 'react-icons/fa';
import axios from 'axios';
import './ToggleSwitch.css';
import { useNavigate } from 'react-router-dom';
import api from '../../services/axiosConfig';

const ToggleSwitch = ({ id, checked, onChange }) => (
  <label className="switch">
    <input type="checkbox" id={id} checked={checked} onChange={onChange} />
    <span className="slider round"></span>
  </label>
);

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const saldoInputRefs = useRef({});

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('+52');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [vendedores, setVendedores] = useState([]);
  const [sortedField, setSortedField] = useState({ field: null, direction: 'asc' });
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [nombreTienda, setNombreTienda] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [saldoValues, setSaldoValues] = useState({});

  
  const fetchVendedores = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get('/admin/vendedores', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVendedores(response.data);
    } catch (error) {
      console.error('Error al obtener los vendedores', error);
    }
  };

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get('/admin/obtener-tiendas', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
     setUsers(response.data.sort((a, b) => {
  const nombreA = a.usuario.nombre_tienda || ''; // Usar una cadena vacía si no está definido
  const nombreB = b.usuario.nombre_tienda || ''; // Usar una cadena vacía si no está definido
  return nombreA.localeCompare(nombreB);
}));
    } catch (error) {
      console.error('Error al obtener los usuarios', error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchVendedores();
    fetchGeolocation(); // Obtener geolocalización al cargar el componente

  }, []);

  const handleSort = (field) => {
    const isAsc = sortedField.field === field && sortedField.direction === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSortedField({ field, direction });
    setUsers([...users].sort((a, b) => {
      if (field === 'nombre_tienda') {
        return direction === 'asc' ? a.usuario.nombre_tienda.localeCompare(b.usuario.nombre_tienda) : b.usuario.nombre_tienda.localeCompare(a.usuario.nombre_tienda);
      } else if (field === 'saldo') {
        return direction === 'asc' ? a.saldo - b.saldo : b.saldo - a.saldo;
      } else if (field === 'vendedor') {
        return direction === 'asc' ? a.creador.correo.localeCompare(b.creador.correo) : b.creador.correo.localeCompare(a.creador.correo);
      }
      return 0;
    }));
  };
   // Control de nombre de tienda: solo permite letras, números y espacios
 const handleNombreTiendaInput = (e) => {
  const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9 ]*$/; // Solo letras, números y espacios
  const input = e.target.value;

  if (regex.test(input)) {
    setNombreTienda(input);
    setMessage({ type: '', text: '' }); // Limpiar el mensaje si todo es válido
  } else {
    setMessage({ type: 'error', text: 'No se permiten caracteres especiales en el nombre de la tienda.' });
  }
};


  const handleAcreditarSaldo = async (id) => {
    const token = localStorage.getItem('token');
    const valor = saldoValues[id];
    if (!isNaN(valor)) {
      try {
        const response = await api.post(
          `/admin/acreditar-saldo2/${id}`,
          {
            valor,
            credito: false,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { tiendaSaldoActual, valorAdeudado } = response.data; // Extraer los valores de la respuesta

        // Actualizar el estado local para reflejar el nuevo saldo y pendiente
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.usuario.id === id
              ? {
                  ...user,
                  saldo: tiendaSaldoActual, // Mostrar tiendaSaldoActual como saldo
                  credito: valorAdeudado, // Mostrar valorAdeudado como pendiente
                }
              : user
          )
        );

        setMessage({ type: 'success', text: 'Saldo acreditado exitosamente' });
        fetchVendedores();
        saldoInputRefs.current[id].blur();
        setSaldoValues("");
      } catch (error) {
        setMessage({ type: 'error', text: 'No se pudo acreditar el saldo' });
        console.error('Error al acreditar el saldo', error);
      }
    }
  };

  const handleSaldoChange = (id, value) => {
    setSaldoValues((prevState) => ({ ...prevState, [id]: parseFloat(value) }));
   
  };


  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e));
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReassignVendedor = async (tiendaId, nuevoVendedorId) => {
    const token = localStorage.getItem('token');
    try {
      await api.patch(
        `/admin/reasignar/${tiendaId}`,
        { nuevoVendedorId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Vendedor reasignado exitosamente' });
      fetchUsers();
    } catch (error) {
      setMessage({ type: 'error', text: 'No se pudo reasignar el vendedor' });
      console.error('Error al reasignar el vendedor', error);
    }
  };
    // Función para evitar que se active la lógica de clic cuando interactúas con los botones internos
    const handleButtonClick = (e, id, route) => {
      e.stopPropagation(); // Detener propagación para que no afecte la selección de la fila
      setTimeout(() => {
        navigate(route); // Navegar al enlace configurado después de restaurar el estado
      }); // Asegurarse de que el estado se restaure antes de navegar
    };

  const handleToggle = async (index, field) => {
    const token = localStorage.getItem('token');
    const user = users[index];
    const previousState = { ...user };

    try {
      user.usuario[field] = !user.usuario[field];
      setUsers([...users]);

      await api.put(
        `/admin/editar-tienda2/${user.usuario.id}`,
        {
          [field]: user.usuario[field],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage({ type: 'success', text: `El campo ${field} ha sido actualizado` });
      setTimeout(() => {
        setMessage({ type: '', text: '' });
      }, 5000);
    } catch (error) {
      setUsers(prevUsers => {
        prevUsers[index] = previousState;
        return [...prevUsers];
      });
      setMessage({ type: 'error', text: `No se pudo actualizar el campo ${field}` });
      console.error(`Error al actualizar el campo ${field}`, error);
    }
  };
    // Nueva función para manejar la entrada del número de teléfono
    const handlePhoneNumberInput = (e) => {
      const input = e.target.value;
      const numbersOnly = input.replace(/\D/g, '');
      const truncatedInput = numbersOnly.slice(0, 10);
      setPhoneNumber(truncatedInput);
    };
  

  const handleCreateTienda = async () => {
    const celular = `${countryCode}${phoneNumber}`;
    const token = localStorage.getItem('token');
    const contrasenia=`${phoneNumber}`;


    // Validación para el celular
    const regexCelular = /^\+?[1-9]\d{1,14}$/;

    if (!nombreTienda) {
      setMessage({ type: 'error', text: 'El nombre de la tienda no puede estar vacío.' });
      return;
    }

    if (phoneNumber.length !== 10) {
      setMessage({ type: 'error', text: 'El número de teléfono debe tener exactamente 10 dígitos.' });
      return;
    }

    if (!celular || !regexCelular.test(celular)) {
      setMessage({ type: 'error', text: 'El número de celular no es válido.' });
      return;
    }



    if (!latitude || !longitude) {
      setMessage({ type: 'error', text: 'Debes permitir acceso a la ubicación para agregar la tienda.' });
      return;
    }

    try {
      await api.post(
        '/admin/crear-por-tienda',
        {
          nombre_tienda: nombreTienda,
          celular,
          latitud: latitude,
          longitud: longitude,
          contrasenia
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Tienda creada exitosamente' });
      setShowModal(false);
      setNombreTienda('');
      setPhoneNumber('');
      fetchUsers();
    } catch (error) {
      setMessage({ type: 'error', text:`${error.response.data.error}` });
      console.error('Error al crear la tienda', error);
    }
  };



  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    try {
      await api.delete(`/admin/eliminar-tienda/${userToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage({ type: 'success', text: 'Tienda y usuario eliminados correctamente.' });
      setShowDeleteModal(false);
      fetchUsers();
    } catch (error) {
      setMessage({ type: 'error', text: 'Error al eliminar la tienda y el usuario.' });
      console.error('Error al eliminar la tienda y el usuario:', error);
    }
  };

  const fetchGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error('Error al obtener la geolocalización', error);
        }
      );
    } else {
      console.error('La geolocalización no es soportada por este navegador.');
    }
  };
  

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };
  const handleKeyPress = (event, id) => {
    if (event.key === 'Enter') {
    
        handleAcreditarSaldo(id);
 
        saldoInputRefs.current[id].blur();
        setSaldoValues("");
      
    }
  };

  const filteredUsers = users.filter(user => {
    const emailMatch = user.usuario.nombre_tienda?.toLowerCase().includes(searchTerm.toLowerCase());
    const nameMatch = user.usuario.nombres_apellidos?.toLowerCase().includes(searchTerm.toLowerCase());
    const dniMatch = user.usuario.dni?.includes(searchTerm);
    return emailMatch || nameMatch || dniMatch;
  });

  const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h1 className="text-left" style={{ color: '#0A74DA' }}>Tiendas</h1>
        </Col>
        <Col className="text-right">
          <Button
            variant="primary"
            style={{ backgroundColor: '#0A74DA', color: '#fff' }}
            onClick={() => setShowModal(true)}
          >
            <FaPlus /> Nuevo
          </Button>
        </Col>
      </Row>

      {message.text && (
        <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
          {message.text}
        </Alert>
      )}

      {/* Filtros y controles para móvil */}
      <Row className="mb-3">
        <Col md={12} className="d-flex flex-wrap gap-2">
          <InputGroup>
            <FormControl
              placeholder="Buscar por nombre"
              onChange={handleSearch}
            />
             <DropdownButton
            as={ButtonGroup}
            variant="outline-secondary"
            title={`Mostrar ${itemsPerPage}`}
            id="dropdown-items-per-page"
            onSelect={handleItemsPerPageChange}
            className="mb-2"
          >
            <Dropdown.Item eventKey="10">10</Dropdown.Item>
            <Dropdown.Item eventKey="20">20</Dropdown.Item>
            <Dropdown.Item eventKey="50">50</Dropdown.Item>
            <Dropdown.Item eventKey="100">100</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey={filteredUsers.length.toString()}>Todo</Dropdown.Item>
          </DropdownButton>
          </InputGroup>
         
        </Col>
      </Row>

      {/* Ordenadores para móvil */}
      <Row className="my-4 d-block d-md-none">
        <Col className="d-flex justify-content-center">
          <ButtonGroup className="mb-2">
            <Button variant="outline-secondary" onClick={() => handleSort('nombre_tienda')}>Ordenar por Nombre</Button>
            <Button variant="outline-secondary" onClick={() => handleSort('saldo')}>Ordenar por Saldo</Button>
            <Button variant="outline-secondary" onClick={() => handleSort('vendedor')}>Ordenar por Vendedor</Button>
          </ButtonGroup>
        </Col>
      </Row>

      {/* Versión de tabla para pantallas grandes */}
      <Table hover className="custom-table d-none d-md-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('nombre_tienda')} style={{ cursor: 'pointer' }}>
              Nombre Tienda {sortedField.field === 'nombre_tienda' && (sortedField.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('saldo')} style={{ cursor: 'pointer' }}>
              Saldo {sortedField.field === 'saldo' && (sortedField.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th>+Saldo</th>
            <th>Promedio Semanal</th>
            <th onClick={() => handleSort('vendedor')} style={{ cursor: 'pointer' }}>
              Vendedor {sortedField.field === 'vendedor' && (sortedField.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th>Ubicación</th>
            <th>Activo</th>
            {/* <th>Verificado</th> */}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.map((user, index) => (
            <tr key={index}>
              <td>{user.usuario.nombre_tienda}</td>
              <td>{user.saldo.toFixed(2)}</td>
              <td>
                <Form.Control
                  type="number"
                  placeholder="Saldo"
                  value={saldoValues[user.usuario.id] || ''}
                  onChange={(e) => handleSaldoChange(user.usuario.id, e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, user.usuario.id, false)}
                  ref={(el) => (saldoInputRefs.current[user.usuario.id] = el)}
                />
              </td>
              <td>{user.promedioSemanal.toFixed(2)}</td>
              <td>
                <Form.Select
                  value={vendedores.find(v => v.correo === user.creador.correo)?.id || user.creador.id}
                  onChange={(e) => handleReassignVendedor(user.id, e.target.value)}
                  style={{
                    borderRadius: '4px',
                    borderColor: '#0A74DA',
                    padding: '5px',
                    transition: 'border-color 0.3s ease',
                    outline: 'none',
                  }}
                >
                  {vendedores.map((vendedor) => (
                    <option key={vendedor.id} value={vendedor.id}>
            {vendedor.nombres_apellidos ? vendedor.nombres_apellidos : "Daniel Fosado"}
            </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                {user.latitud && user.longitud ? (
                  <a
                    href={`https://www.google.com/maps?q=${user.latitud},${user.longitud}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {`${user.latitud} | ${user.longitud}`}
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td>
                <ToggleSwitch
                  id={`activo-switch-${index}`}
                  checked={user.usuario.activo}
                  onChange={() => handleToggle(index, 'activo')}
                />
              </td>
              {/* <td>
                <ToggleSwitch
                  id={`verificado-switch-${index}`}
                  checked={user.usuario.verificado}
                  onChange={() => handleToggle(index, 'verificado')}
                />
              </td> */}
              <td> <ButtonGroup>
                <Button variant="danger" onClick={() => handleOpenDeleteModal(user)}>
                  Eliminar
                </Button>
                 {/* Botón con Tooltip para FaAddressBook */}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-top`}>Configuración</Tooltip>}
      >
        <Button variant="link" onClick={(e) => handleButtonClick(e, user.usuario.id, `/admin/usuario-configuracion/${user.id}`)}>
          <FaAddressBook />
        </Button>
      </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

{/* Versión de tarjetas para pantallas pequeñas */}
<div className="d-md-none">
  {paginatedUsers.map((user, index) => (
    <div key={index} className="user-card">
 <div className="user-info correo-container">
        <strong>Nombre Tienda:</strong> 
        <span className="correo-data">{user.usuario.nombre_tienda}</span>
      </div>
      <div className="user-info">
        <strong>Saldo:</strong>
        <strong>Promedio:</strong>
        <strong>+Saldo:</strong>
       
      </div>
      <div className="user-info">
        <span className="user-data">${user.saldo.toFixed(2)}</span>
        <span className="user-data">${user.promedioSemanal.toFixed(2)}</span>
        <div className="saldo-input-container">
      

<form onSubmit={(e) => {
      e.preventDefault();
      handleAcreditarSaldo(user.usuario.id);
    }}>
      <Form.Control
        type="number"
        placeholder="Saldo"
        value={saldoValues[user.usuario.id] || ''}
        onChange={(e) => handleSaldoChange(user.usuario.id, e.target.value)}
        ref={(el) => (saldoInputRefs.current[user.usuario.id] = el)}
        style={{
          width:'85px', // Ajusta el ancho según sea necesario
        }}
      />
    </form>
        </div>
      </div>
      <div className="user-info">
        <strong>Vendedor:</strong>
        <Form.Select
          value={vendedores.find(v => v.correo === user.creador.correo)?.id || user.creador.id}
          onChange={(e) => handleReassignVendedor(user.id, e.target.value)}
          style={{
            borderRadius: '4px',
            borderColor: '#0A74DA',
            padding: '5px',
            transition: 'border-color 0.3s ease',
            outline: 'none',
          }}
        >
        {vendedores.map((vendedor) => (
          <option key={vendedor.id} value={vendedor.id}>
            {vendedor.nombres_apellidos ? vendedor.nombres_apellidos : "Daniel Fosado"}
          </option>
        ))}
        </Form.Select>
      </div>
      <div className="user-info">
        <strong>Ubicación:</strong>{' '}
        {user.latitud && user.longitud ? (
          <a
            href={`https://www.google.com/maps?q=${user.latitud},${user.longitud}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {`${user.latitud} | ${user.longitud}`}
          </a>
        ) : (
          'N/A'
        )}
      </div>
     

      <div className="user-info actions-container">
        <div className="action-item">
          <strong>Activo:</strong> 
          <ToggleSwitch
            id={`activo-switch-${index}`}
            checked={user.usuario.activo}
            onChange={() => handleToggle(index, 'activo')}
          />
        </div>
        {/* <div className="action-item">
          <strong>Verificado:</strong> 
          <ToggleSwitch
            id={`verificado-switch-${index}`}
            checked={user.usuario.verificado}
            onChange={() => handleToggle(index, 'verificado')}
          />
        </div> */}
        <div className="action-item">
          <Button variant="outline-danger" onClick={() => handleOpenDeleteModal(user)}>
            Eliminar
          </Button>
          <Button
                  variant="outline-primary"
                  onClick={(e) => handleButtonClick(e, user.usuario.id, `/admin/usuario-configuracion/${user.id}`)}
                >
                 Config.
                </Button>
        </div>
       
      </div>
    </div>
  ))}
</div>

<Pagination className="justify-content-center">
  {[...Array(Math.ceil(filteredUsers.length / itemsPerPage)).keys()].map(number => (
    <Pagination.Item
      key={number + 1}
      className={number + 1 === currentPage ? "active" : ""} // Añade la clase "active"
      onClick={() => handlePageChange(number + 1)}
    >
      {number + 1}
    </Pagination.Item>
  ))}
</Pagination>

      

      

      {/* Modal de Confirmación de Eliminación */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userToDelete ? `¿Estás seguro de eliminar ${userToDelete.usuario.nombre_tienda}?` : ''}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nueva Tienda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {message.text && (
          <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
            {message.text}
          </Alert>
        )}
        <Form>
          <Form.Group controlId="formNombreTienda">
            <Form.Label>Nombre de la Tienda</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa el nombre de la tienda"
              value={nombreTienda}
              onChange={handleNombreTiendaInput}
              required
            />
          </Form.Group>

          <Form.Group controlId="formPhoneNumber">
            <Form.Label>Número de WhatsApp</Form.Label>
            <Row>
              <Col md={4}>
                <Form.Control
                  as="select"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  <option value="+52">🇲🇽 +52 México</option>
                  <option value="+1">🇺🇸 +1 USA</option>
                  <option value="+34">🇪🇸 +34 España</option>
                </Form.Control>
              </Col>
              <Col md={8}>
                <Form.Control
                  type="tel"
                  placeholder="Ingresa el número de teléfono"
                  value={phoneNumber}
                  onChange={handlePhoneNumberInput} // Usamos la nueva función
                  required
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: '#0A74DA', color: '#fff' }}
            onClick={handleCreateTienda}
          >
            Crear Usuario
          </Button>
        </Modal.Footer>
      </Modal>

      <style>{`
  .custom-table {
    border-collapse: collapse;
    width: 100%;
  }
  .custom-table thead th {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  .custom-table tbody tr {
    transition: background-color 0.3s ease;
  }
  .custom-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  .user-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
  }

  .user-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  .correo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px; /* Espacio mínimo entre "Correo:" y el dato */
  }
  .correo-container strong {
    margin-right: 2px; /* Reduce el margen entre "Correo:" y el dato */
  }
  .correo-data {
    text-align: left;
    flex-grow: 1; /* Permite que el dato del correo se mantenga alineado a la izquierda */
    margin-left: 0; /* Elimina cualquier margen adicional */
    white-space: nowrap; /* Evita que el texto del correo se rompa en líneas */
  }
  .actions-container {
    display: flex;
    align-items: center; /* Centra los elementos verticalmente */
    justify-content: space-between; /* Espacia los elementos uniformemente */
    gap: 10px; /* Añade espacio entre los elementos */
    margin-top: 10px; /* Espacio superior para separar de otros bloques */
  }
  .action-item {
    display: flex;
    align-items: center; /* Centra verticalmente los textos y botones */
    justify-content: center; /* Centra los elementos dentro de cada acción */
    gap: 5px; /* Espacio entre el texto y el botón */
  }
  .action-item strong {
    margin-right: 5px; /* Ajusta el espaciado entre el texto y el interruptor */
  }
  .switch {
    display: inline-flex; /* Asegura que el interruptor se mantenga alineado */
    align-items: center; /* Centra verticalmente dentro del contenedor */
  }
`}</style>
    </Container>
  );
};

export default ManageUsers;
