import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Button,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { startOfWeek, isWithinInterval, parseISO } from "date-fns";
import "./Historial.css"; // Asegúrate de tener este archivo CSS para los estilos
import api from '../../services/axiosConfig';

const Historial = () => {
  const [selectedOption, setSelectedOption] = useState("Aperturas");
  const [comision, setComision] = useState(0);
  const [aperturasSemanales, setAperturasSemanales] = useState(0);
  const [pendienteRecargas, setPendienteRecargas] = useState(0);
  const [pendienteDepositar, setPendienteDepositar] = useState(0);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [endDate, setEndDate] = useState(new Date());
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    fetchSummaryData();
    fetchData(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    applyDateFilter();
  }, [data, startDate, endDate]);

  const fetchSummaryData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(
        "/admin/comision-semanal",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { comision, cantidadTiendas, valor_recarga, valor_adeudado } =
        response.data;
      setComision(comision);
      setAperturasSemanales(cantidadTiendas);
      setPendienteRecargas(valor_recarga);
      setPendienteDepositar(valor_adeudado);
    } catch (error) {
      console.error("Error al obtener los datos de resumen:", error);
      if (error.response && error.response.status === 401) {
        alert("Sesión expirada. Por favor, inicie sesión nuevamente.");
        window.location.href = "/login";
      }
    }
  };

  const fetchData = async (option) => {
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(
        `/admin/historial/${option.toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedData = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setData(sortedData);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const applyDateFilter = () => {
    const filtered = data.filter((item) => {
      let dateToCheck = null;

      if (selectedOption.toLowerCase() === "saldos") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else if (selectedOption.toLowerCase() === "depositos") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else if (selectedOption.toLowerCase() === "ventas") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else if (selectedOption.toLowerCase() === "recargas") {
        dateToCheck = item.fecha ? parseISO(item.fecha) : null;
      } else {
        dateToCheck = item.createdAt ? parseISO(item.createdAt) : null;
      }

      return (
        dateToCheck &&
        isWithinInterval(dateToCheck, { start: startDate, end: endDate })
      );
    });
    setFilteredData(filtered);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sorted = [...filteredData].sort((a, b) => {
      let aValue = getNestedValue(a, key);
      let bValue = getNestedValue(b, key);

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredData(sorted);
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : null), obj);
  };

  const renderTableColumns = () => {
    switch (selectedOption.toLowerCase()) {
      case "aperturas":
        return (
          <>
            <th onClick={() => handleSort("usuario.nombre_tienda")}>Nombre</th>
            <th onClick={() => handleSort("createdAt")}>Fecha Creación</th>
            <th onClick={() => handleSort("updatedAt")}>
              Fecha De Eliminación
            </th>
            <th onClick={() => handleSort("usuario.celular")}>Celular</th>
            <th onClick={() => handleSort("ubicacion")}>Ubicación</th>
            <th onClick={() => handleSort("promedioSemanal")}>
              Promedio Semanal
            </th>
          </>
        );
      case "saldos":
        return (
          <>
            <th onClick={() => handleSort("tienda.usuario.nombre_tienda")}>
              Tienda
            </th>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("valor")}>Valor</th>
          </>
        );
      case "depositos":
        return (
          <>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("valor")}>Valor</th>
            <th onClick={() => handleSort("tipo")}>Tipo</th>
          </>
        );
      case "ventas":
        return (
          <>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("folio")}>Folio</th>
            <th onClick={() => handleSort("celular")}>Número</th>
            <th onClick={() => handleSort("valor")}>Monto</th>
            <th onClick={() => handleSort("operadora")}>Compañía</th>
            <th onClick={() => handleSort("clase")}>Clase</th>
          </>
        );
      case "recargas":
        return (
          <>
            <th onClick={() => handleSort("fecha")}>Fecha</th>
            <th onClick={() => handleSort("hora")}>Hora</th>
            <th onClick={() => handleSort("valor")}>Valor</th>
            <th onClick={() => handleSort("tipoMovimiento")}>Tipo</th>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderTableRows = () => {
    return filteredData.map((item, index) => (
      <tr
        key={index}
        style={{
          backgroundColor: item.usuario?.eliminado ? "#ffcccc" : "transparent",
        }}
      >
        {selectedOption.toLowerCase() === "aperturas" && (
          <>
            <td>{item.usuario?.nombre_tienda}</td>
            <td>
              {item.createdAt
                ? new Date(item.createdAt).toLocaleString()
                : "N/A"}
            </td>
            <td>
              {item.usuario?.eliminado
                ? item.updatedAt
                  ? new Date(item.updatedAt).toLocaleString()
                  : "N/A"
                : ""}
            </td>
            <td>{item.usuario?.celular}</td>
            <td>
              <a
                href={`https://www.google.com/maps?q=${item.latitud},${item.longitud}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${item.latitud}, ${item.longitud}`}
              </a>
            </td>
            <td>{item.promedioSemanal}</td>
          </>
        )}

        {selectedOption.toLowerCase() === "saldos" && (
          <>
            <td>{item.tienda?.usuario?.nombre_tienda || "N/A"}</td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td style={{ color: item.valor < 0 ? "lightcoral" : "black" }}>
              {item.valor}
            </td>
          </>
        )}

        {selectedOption.toLowerCase() === "depositos" && (
          <>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td>{item.valor}</td>
            <td style={{ color: item.tipo === "Deposito" ? "green" : "black" }}>
              {item.tipo === "Deposito"
                ? "Depósito"
                : `Recibido - ${
                    item.tienda?.usuario?.nombre_tienda || "N/A"
                  }`}
            </td>
          </>
        )}

        {selectedOption.toLowerCase() === "ventas" && (
          <>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td>{item.folio || "N/A"}</td>
            <td>{item.celular || "N/A"}</td>
            <td>{item.valor}</td>
            <td>{item.operadora || "N/A"}</td>
            <td>
              {item.clase
                ? item.clase.charAt(0).toUpperCase() +
                  item.clase.slice(1).toLowerCase()
                : "N/A"}
            </td>
          </>
        )}

        {selectedOption.toLowerCase() === "recargas" && (
          <>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}
            </td>
            <td>
              {item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A"}
            </td>
            <td>{item.valor}</td>
            <td>
              {item.tipoMovimiento}
              {item.tipoMovimiento === "Recarga" && item.celular && (
                <div style={{ fontSize: "smaller", color: "gray" }}>
                  {`Celular: ${item.celular}`}
                </div>
              )}
            </td>
          </>
        )}
      </tr>
    ));
  };

  const renderMobileRows = () => {
    return filteredData.map((item, index) => {
      if (selectedOption.toLowerCase() === "aperturas") {
        return (
          <div
            key={index}
            className={`mobile-card ${
              item.usuario?.eliminado ? "deleted-row" : ""
            }`}
          >
            <div className="mobile-row">
              <div>
                <strong>Nombre:</strong> {item.usuario?.nombre_tienda}
              </div>
              <div>
                <strong>Fecha Creación:</strong>{" "}
                {item.createdAt
                  ? new Date(item.createdAt).toLocaleString()
                  : "N/A"}
              </div>
              
            </div>
            
           
            <div className="mobile-row">
              <div>
                <strong>Ubicación:</strong>{" "}
                <a
                  href={`https://www.google.com/maps?q=${item.latitud},${item.longitud}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${item.latitud}, ${item.longitud}`}
                </a>
              </div>
              <div>
                <strong>Promedio Semanal:</strong> {item.promedioSemanal}
              </div>
            </div>
            <div className="mobile-row">
             
             {item.usuario?.eliminado && (
               <div>
                 <strong>Fecha De Eliminación:</strong>{" "}
                 {item.updatedAt
                   ? new Date(item.updatedAt).toLocaleString()
                   : "N/A"}
               </div>
             )}
           </div>
          </div>
        );
      } else if (selectedOption.toLowerCase() === "ventas") {
        return (
          <div key={index} className="mobile-card">
            <div className="mobile-row">
              <div>
                <strong>Fecha:</strong>{" "}
                {item.fecha ? new Date(item.fecha).toLocaleDateString() : "N/A"}  <br/> {item.fecha
                  ? new Date(item.fecha).toLocaleTimeString()
                  : "N/A"}
              </div>
      
              <div>
                <strong>Folio:</strong> {item.folio || "N/A"}
              </div>
              <div>
                <strong>Número:</strong> {item.celular || "N/A"}
              </div>
            </div>
            
            <div className="mobile-row">
           
              <div>
                <strong>Monto:</strong> {item.valor}
              </div>
              <div>
                <strong>Compañía:</strong> {item.operadora || "N/A"}
              </div>
              <div>
                <strong>Clase:</strong>{" "}
                {item.clase
                  ? item.clase.charAt(0).toUpperCase() +
                    item.clase.slice(1).toLowerCase()
                  : "N/A"}
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const exportToExcel = async () => {
    // Capturar el estado actual al inicio de la función
    const selectedOptionSnapshot = selectedOption;
    const startDateSnapshot = startDate;
    const endDateSnapshot = endDate;

    // Obtener la clave interna y el título basado en el mapeo
    const optionMap = {
      Aperturas: { internalOption: "aperturas", title: "Aperturas" },
      Saldos: { internalOption: "saldos", title: "Saldos" },
      Depositos: {
        internalOption: "depositos",
        title: "Recibido y Depósitos",
      },
      Ventas: { internalOption: "ventas", title: "Ventas" },
      Recargas: {
        internalOption: "recargas",
        title: "Historial de Recargas",
      },
    };
    const selectedOptionData = optionMap[selectedOptionSnapshot];
    const internalOption = selectedOptionData?.internalOption || "";
    const title = selectedOptionData?.title || selectedOptionSnapshot;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Historial");

    // Determinar las columnas basadas en la clave interna
    const columns = (() => {
      switch (internalOption) {
        case "aperturas":
          return [
            { header: "Nombre", key: "nombre", width: 25 },
            { header: "Fecha Creación", key: "createdAt", width: 20 },
            { header: "Fecha De Eliminación", key: "updatedAt", width: 20 },
            { header: "Celular", key: "celular", width: 15 },
            { header: "Ubicación", key: "ubicacion", width: 30 },
            { header: "Promedio Semanal", key: "promedioSemanal", width: 20 },
          ];
        case "saldos":
          return [
            { header: "Tienda", key: "tienda", width: 25 },
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Valor", key: "valor", width: 15 },
          ];
        case "depositos":
          return [
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Valor", key: "valor", width: 15 },
            { header: "Tipo", key: "tipo", width: 30 },
          ];
        case "ventas":
          return [
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Folio", key: "folio", width: 15 },
            { header: "Número", key: "numero", width: 20 },
            { header: "Monto", key: "monto", width: 15 },
            { header: "Compañía", key: "compania", width: 20 },
            { header: "Clase", key: "clase", width: 15 },
          ];
        case "recargas":
          return [
            { header: "Fecha", key: "fecha", width: 15 },
            { header: "Hora", key: "hora", width: 15 },
            { header: "Valor", key: "valor", width: 15 },
            { header: "Tipo", key: "tipo", width: 30 },
          ];
        default:
          return [];
      }
    })();

    // Número de columnas
    const columnCount = columns.length;

    // Agregar encabezado con título y fechas
    worksheet.mergeCells(1, 1, 1, columnCount);
    worksheet.getCell("A1").value = title;
    worksheet.getCell("A1").font = { size: 16, bold: true };
    worksheet.getCell("A1").alignment = { horizontal: "center" };

    // Establecer fechas
    worksheet.mergeCells(2, 1, 2, columnCount);
    worksheet.getCell(
      "A2"
    ).value = `Fecha de Inicio: ${startDateSnapshot.toLocaleDateString()}  |  Fecha de Corte: ${endDateSnapshot.toLocaleDateString()}`;
    worksheet.getCell("A2").font = { size: 12 };
    worksheet.getCell("A2").alignment = { horizontal: "center" };

    // Eliminar 'header' de las columnas al configurar worksheet.columns
    const columnsWithoutHeader = columns.map(({ header, ...rest }) => rest);
    worksheet.columns = columnsWithoutHeader;

    // Aplicar estilo a los encabezados de columna (fila 3)
    worksheet.getRow(3).font = { bold: true, color: { argb: "FF000000" } }; // Negro

    // Agregar los encabezados de columna manualmente
    columns.forEach((column, index) => {
      const cell = worksheet.getCell(3, index + 1);
      cell.value = column.header;
      cell.font = { bold: true, color: { argb: "FF000000" } }; // Negro y negrita
      cell.alignment = { horizontal: "center" };
    });

    // Iniciar desde la fila 4 para dejar espacio al encabezado
    const startingRow = 4;

    // Crear un formateador de moneda
    const currencyFormatter = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    });

    // Agregar datos
    filteredData.forEach((item, dataIndex) => {
      let rowData = {};

      switch (internalOption) {
        case "aperturas":
          rowData = {
            nombre: item.usuario?.nombre_tienda || "N/A",
            createdAt: item.createdAt
              ? new Date(item.createdAt).toLocaleString()
              : "N/A",
            updatedAt: item.usuario?.eliminado
              ? item.updatedAt
                ? new Date(item.updatedAt).toLocaleString()
                : "N/A"
              : "",
            celular: item.usuario?.celular || "N/A",
            ubicacion:
              item.latitud && item.longitud
                ? `${item.latitud}, ${item.longitud}`
                : "N/A",
            promedioSemanal: item.promedioSemanal || "N/A",
          };
          break;
        case "saldos":
          rowData = {
            tienda: item.tienda?.usuario?.nombre_tienda || "N/A",
            fecha: item.fecha
              ? new Date(item.fecha).toLocaleDateString()
              : "N/A",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A",
            valor: currencyFormatter.format(item.valor / 100),
          };
          break;
        case "depositos":
          rowData = {
            fecha: item.fecha
              ? new Date(item.fecha).toLocaleDateString()
              : "N/A",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A",
            valor: currencyFormatter.format(item.valor / 100),
            tipo:
              item.tipo === "Deposito"
                ? "Depósito"
                : `Recibido - ${item.tienda?.usuario?.nombre_tienda || "N/A"}`,
          };
          break;
        case "ventas":
          rowData = {
            fecha: item.fecha
              ? new Date(item.fecha).toLocaleDateString()
              : "N/A",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A",
            folio: item.folio || "N/A",
            numero: item.celular || "N/A",
            monto: currencyFormatter.format(item.valor / 100),
            compania: item.operadora || "N/A",
            clase: item.clase
              ? item.clase.charAt(0).toUpperCase() +
                item.clase.slice(1).toLowerCase()
              : "N/A",
          };
          break;
        case "recargas":
          rowData = {
            fecha: item.fecha
              ? new Date(item.fecha).toLocaleDateString()
              : "N/A",
            hora: item.fecha ? new Date(item.fecha).toLocaleTimeString() : "N/A",
            valor: currencyFormatter.format(item.valor / 100),
            tipo:
              item.tipoMovimiento === "Recarga" && item.celular
                ? `${item.tipoMovimiento} - Celular: ${item.celular}`
                : item.tipoMovimiento,
          };
          break;
        default:
          break;
      }

      // Agregar la fila de datos
      const rowIndex = startingRow + dataIndex;
      worksheet.addRow(rowData);

      // Aplicar formato a las celdas si es necesario
      const row = worksheet.getRow(rowIndex);
      row.eachCell((cell) => {
        cell.font = { color: { argb: "FF000000" } }; // Negro
      });
    });

    // Ajustar el ancho de las columnas si es necesario
    worksheet.columns.forEach((column) => {
      column.width = column.width < 20 ? 20 : column.width;
    });

    // Guardar el workbook
    const filename = `${title}.xlsx`;
    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), filename);
  };

  return (
    <Container>
      <br />
      <Row>
        <Col md={12} className="d-flex justify-content-between mb-3">
          <h1 className="text-left" style={{ color: "#0A74DA" }}>
            Historial
          </h1>
          <Button variant="success" onClick={exportToExcel}>
            Descargar
          </Button>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <div>
            <strong>Comisión:</strong> ${comision}
          </div>
        </Col>
        <Col md={3}>
          <div>
            <strong>Aperturas semanales:</strong> {aperturasSemanales}
          </div>
        </Col>
        <Col md={3}>
          <div>
            <strong>Pendiente de recargas:</strong> ${pendienteRecargas}
          </div>
        </Col>
        <Col md={3}>
          <div>
            <strong>Pendiente de depositar:</strong> ${pendienteDepositar}
          </div>
        </Col>
      </Row>
      <Row className="mb-4 align-items-center">
  <Col xs={12} md={4} className="mb-3">
    <DropdownButton
      title={selectedOption}
      onSelect={(e) => setSelectedOption(e)}
      variant="outline-secondary"
      className="w-100"
    >
      <Dropdown.Item eventKey="Aperturas">Aperturas</Dropdown.Item>
      <Dropdown.Item eventKey="Saldos">Saldos</Dropdown.Item>
      <Dropdown.Item eventKey="Depositos">
        Recibido y depósitos
      </Dropdown.Item>
      <Dropdown.Item eventKey="Ventas">Ventas</Dropdown.Item>
      <Dropdown.Item eventKey="Recargas">
        Historial de recargas
      </Dropdown.Item>
    </DropdownButton>
  </Col>

  <Col xs={6} md="auto" className="mb-2 ml-md-auto">
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        if (date > endDate) {
          setEndDate(date);
        }
      }}
      placeholderText="Fecha de inicio"
      dateFormat="dd/MM/yyyy"
      maxDate={new Date()}
      className="form-control"
    />
  </Col>
  <Col xs={6} md="auto" className="mb-2">
    <DatePicker
      selected={endDate}
      onChange={(date) => {
        setEndDate(date);
        if (date < startDate) {
          setStartDate(date);
        }
      }}
      placeholderText="Fecha de fin"
      dateFormat="dd/MM/yyyy"
      maxDate={new Date()}
      minDate={startDate}
      className="form-control"
    />
  </Col>
</Row>

      <Row>
        <Col md={12}>
          {(selectedOption.toLowerCase() !== "aperturas" &&
            selectedOption.toLowerCase() !== "ventas") && (
            <Table hover className="custom-table">
              <thead>
                <tr>{renderTableColumns()}</tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </Table>
          )}

          {(selectedOption.toLowerCase() === "aperturas" ||
            selectedOption.toLowerCase() === "ventas") && (
            <>
              <div className="d-none d-md-block">
                <Table hover className="custom-table">
                  <thead>
                    <tr>{renderTableColumns()}</tr>
                  </thead>
                  <tbody>{renderTableRows()}</tbody>
                </Table>
              </div>
              <div className="d-md-none mobile-cards">{renderMobileRows()}</div>
            </>
          )}
        </Col>
      </Row>

      <style>{`
        .custom-table {
          border-collapse: collapse;
          width: 100%;
        }
        .custom-table thead th {
          border-bottom: 1px solid #ddd;
        }
        .custom-table tbody tr {
          transition: background-color 0.3s ease;
        }
        .custom-table tbody tr:hover {
          background-color: #f1f1f1;
        }
        .custom-table tbody tr:nth-child(odd) {
          background-color: #f9f9f9;
        }
        .custom-table tbody tr:nth-child(even) {
          background-color: #ffffff;
        }
        .custom-table td,
        .custom-table th {
          border: none;
          padding: 12px 15px;
        }
        .mobile-cards {
          display: flex;
          flex-direction: column;
          gap: 0;
        }
        .mobile-card {
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          background-color: #fff;
          transition: background-color 0.3s ease;
        }
        .deleted-row {
          background-color: #ffcccc;
        }
        .mobile-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 5px 0;
        }
        .mobile-row > div {
          flex: 1 1 30%;
          margin-bottom: 5px;
        }
        .mobile-row strong {
          display: block;
        }
        @media (min-width: 768px) {
          .mobile-cards {
            display: none;
          }
        }
      `}</style>
    </Container>
  );
};

export default Historial;
