import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import './ToggleSwitch.css';
import { useParams } from 'react-router-dom';
import api from '../../services/axiosConfig';

const ConfiguracionUsuario = () => {
  const { id } = useParams();
  const [tienda, setTienda] = useState(null);
  const [ordenGeografico, setOrdenGeografico] = useState(0);
  const [soloContado, setSoloContado] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [errorPorcentaje, setErrorPorcentaje] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(''); // Mensaje del modal
  const [maxOrden, setMaxOrden] = useState(0);
  const [selectedOrden, setSelectedOrden] = useState(1);
  const [porcentaje, setPorcentaje] = useState('');
  const [confirmAction, setConfirmAction] = useState(''); // Almacena la acción que se está confirmando

  useEffect(() => {
    fetchTienda();
  }, [id]);

  // Función para obtener los datos de la tienda desde el backend
  const fetchTienda = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`/admin/obtener-tienda/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const tiendaData = response.data;
      tiendaData.usuario.createdAt = new Date(tiendaData.usuario.createdAt).toLocaleString(); // Formato local
      setTienda(tiendaData);
      setOrdenGeografico(tiendaData.ordenGeografico);
      setSoloContado(tiendaData.contado);
      setMaxOrden(tiendaData.maxOrden);
      setSelectedOrden(tiendaData.orden);
      setPorcentaje(tiendaData.porcentaje);
    } catch (error) {
      console.error('Error al obtener la tienda', error);
    }
  };

  // Manejar el cambio del toggle
  const handleToggleChange = () => {
    if (soloContado) {
      // Si está en solo contado (true), mostrar confirmación de financiamiento
      setConfirmMessage('¿Estás seguro de que quieres habilitar crédito en esta tienda?');
    } else {
      // Si está en financiamiento (false), mostrar confirmación de solo efectivo
      setConfirmMessage('¿Estás seguro de que todos los pagos sean en efectivo en esta tienda?');
    }
    setConfirmAction('toggle'); // Establece la acción
    setShowConfirmModal(true); // Mostrar el modal de confirmación
  };

  // Confirmar el cambio en el estado de solo contado
  const confirmToggleChange = async () => {
    const token = localStorage.getItem('token');
    try {
      await api.post(
        `/admin/actualizar-contado`,
        { tiendaId: id, contado: !soloContado },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSoloContado(!soloContado);
      setMessage({
        type: 'success',
        text: soloContado
          ? 'Ahora estás ofreciendo financiamiento en esta tienda.'
          : 'Esta tienda solo podrá hacer pagos en efectivo.',
      });
    } catch (error) {
      setMessage({ type: 'error', text: 'Error al cambiar el estado de financiamiento.' });
    }
    setShowConfirmModal(false); // Cerrar el modal de confirmación
  };

  // Función para manejar el cambio del porcentaje
  const handlePorcentajeChange = (event) => {
    const value = event.target.value;
    setErrorPorcentaje('');
    setPorcentaje(value);

    // Validar el valor del porcentaje
    if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 7)) {
      setErrorPorcentaje('');
    } else {
      setErrorPorcentaje('El porcentaje debe estar entre 0 y 7');
    }
  };

  // Función para manejar la tecla Enter
  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && porcentaje !== '') {
      const parsedPorcentaje = parseFloat(porcentaje);
      if (parsedPorcentaje >= 0 && parsedPorcentaje <= 7) {
        const token = localStorage.getItem('token');
        try {
          await api.post(
            `/admin/actualizar-porcentaje`,
            { tiendaId: id, porcentaje: parsedPorcentaje },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setMessage({ type: 'success', text: 'Porcentaje actualizado correctamente.' });
          event.target.blur();
        } catch (error) {
          setMessage({ type: 'error', text: 'Error al actualizar el porcentaje.' });
          console.error('Error al actualizar el porcentaje', error);
        }
      } else {
        setErrorPorcentaje('El porcentaje debe estar entre 0 y 7');
      }
    }
  };


  // Función para restablecer la contraseña
  const handleResetPassword = async () => {
    const token = localStorage.getItem('token');
    try {
      await api.post(
        `/admin/restablecer-contrasenia`,
        { tiendaId: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: 'success', text: 'Contraseña restablecida exitosamente.' });
      window.location.reload();
    } catch (error) {
      setMessage({ type: 'error', text: 'Error al restablecer la contraseña' });
      console.error('Error al restablecer la contraseña', error);
    }
    setShowConfirmModal(false);
  };

  // Función para mostrar el modal de restablecer contraseña
  const handleResetPasswordClick = () => {
    setConfirmAction('resetPassword'); // Asigna la acción de restablecer contraseña
    setConfirmMessage('¿Estás seguro de que deseas restablecer la contraseña del usuario?');
    setShowConfirmModal(true);
  };

  // Acción al confirmar en el modal
  const handleConfirmAction = () => {
    if (confirmAction === 'toggle') {
      confirmToggleChange();
    } else if (confirmAction === 'resetPassword') {
      handleResetPassword();
    }
  };

  if (!tienda) {
    return <div>Cargando...</div>;
  }

  return (
    <Container>
      <Row className="my-2 align-items-center">
        <Col xs={8} sm={10} className="mb-1">
          <h1 className="text-left" style={{ color: '#0A74DA', marginBottom: '0.5rem' }}>
            Configuración Usuarios
          </h1>
        </Col>

        {message.text && (
          <Alert variant={message.type === 'success' ? 'success' : 'danger'}>{message.text}</Alert>
        )}
      </Row>

      <Form>
        {/* Campos existentes */}
        <Form.Group controlId="formNombreTienda">
          <Form.Label>Nombre de la Tienda</Form.Label>
          <Form.Control type="text" value={tienda.usuario?.nombre_tienda} readOnly />
        </Form.Group>
        <Form.Group controlId="formFechaCreacion">
          <Form.Label>Fecha de creación</Form.Label>
          <Form.Control type="text" value={tienda.usuario?.createdAt} readOnly />
        </Form.Group>
        <Form.Group controlId="formTelefono">
          <Form.Label>Número telefónico</Form.Label>
          <Form.Control type="text" value={tienda.usuario?.celular} readOnly />
        </Form.Group>

        {/* Campo editable para el porcentaje */}
        <Form.Group controlId="formPorcentaje">
          <Form.Label>Porcentaje</Form.Label>
          <Form.Control
            type="number"
            step="0.1"
            min="0"
            max="7"
            value={porcentaje}
            onChange={handlePorcentajeChange}
            onKeyDown={handleKeyDown}
            isInvalid={errorPorcentaje !== ''}
          />
          <Form.Control.Feedback type="invalid">{errorPorcentaje}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formCorreo">
          <Form.Label>Correo</Form.Label>
          <Form.Control type="text" value={tienda.usuario?.correo} readOnly />
        </Form.Group>

        {/* Toggle Switch */}
        <Form.Group controlId="formSoloContado">
          <Form.Label>Solo al Contado</Form.Label>
          <br />
          <label className="switch">
            <input type="checkbox" checked={soloContado} onChange={handleToggleChange} />
            <span className="slider round"></span>
          </label>
        </Form.Group>

  

        {/* Botón para restablecer contraseña */}
        <Button variant="danger" onClick={handleResetPasswordClick} className="mt-3">
          Restablecer Contraseña
        </Button>
      </Form>

      {/* Modal de Confirmación */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleConfirmAction}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ConfiguracionUsuario;
